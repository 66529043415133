/**
 * Simple debounce function
 * @param {Function} func
 * @param {?number} timeout
 * @return {function(...[*[]]): void}
 */
export default function debounce(func, timeout) {
  let timer;
  return (...args) => {
    const next = () => func(...args);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(next, timeout > 0 ? timeout : 300);
  };
}
